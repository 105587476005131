(function ($) {
  $(document).on('change', '.js-product-qty-select', function () {
    var $product = $(this).closest('.js-product');
    var $addToBag = $('.js-add-to-cart', $product);
    var newQty = $(this).val() - 0;

    $addToBag.attr('data-qty', newQty);
    $addToBag.data('qty', newQty);
    $product.trigger('product.qtySelect', [newQty]);
    if (Drupal.settings.common.clearpay_price) {
      Drupal.behaviors.productInstallmentPriceUKV1.initClearPay();
    }
    if (Drupal.settings.common.klarna_installment) {
      document.dispatchEvent(new CustomEvent('klarnaAccount:attach', { detail: {
        context: document,
        qty: newQty
      }}));
    }
  });
})(jQuery);
